<template>
    <ExamineItem v-if="selected_item" :product="selected_item" @close="selected_item = null"/>
    <div class="products">
        <div class="tools">
            <div class="search">
                <el-input
                    placeholder="חיפוש"
                    prefix-icon="el-icon-search"
                    v-model="search"
                >
                </el-input>
            </div>
        </div>
        <div class="table-box">
            <table id="table">
                <tr>
                    <th>תמונה</th>
                    <th>מקט</th>
                    <th>שם</th>
                    <th>תת מחלקה</th>
                    <th></th>
                </tr>
                 <div class="no-results" v-if="results.length==0">
                    <h1>עליך לחפש פריט באמצעות מקט או שם </h1>
                </div>
                <template v-for="item in results" :key="item.makat">
                    <tr>
                        <td><img :src="item.image"></td>
                        <td>{{item.makat}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.sub_category}}</td>
                        <td><i class="material-icons" style="cursor:pointer;" @click="selected_item = item">settings_applications</i></td>
                    </tr>
                </template>
            </table>
        </div>
       
    </div>
  
</template>

<script>
import {onMounted, ref, watch } from '@vue/runtime-core'
import ExamineItem from './ItemExamine.vue';
import { projectFunctions } from '../../../firebase/config';
export default {
    components:{ExamineItem},
    setup(){
        const selected_item = ref(null)
        const search = ref('')
        const results = ref([])
    
        watch(search,async ()=>{
            if(search.value==''){
                results.value=[]
            }else{
               results.value = await (await projectFunctions.httpsCallable('search_algolia')
                ({search:search.value,index:'products',res_per_page:20})).data
            }
        })

        onMounted(async()=>{
            await projectFunctions.httpsCallable('init_algolia')()
        })
        

        return{results,search,selected_item}
    }
}
</script>

<style scoped>
    .products{
        width: 100%;
        height: 100%;
    }
    .tools{
        width: 100%;
        height: 80px;
        background: var(--alert-purple);
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 5px;
        overflow-x: auto;
    }
    .tools .search{
        width: 40%;
    }
    .table-box{
        padding: 5px;
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: auto;
    }
    .table-box{
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: auto;
    }
    #table {
        margin-top: 10px;
        border-collapse: collapse;
        width: 100%;
    }
    #table td, #table th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    #table tr:nth-child(even){background-color: #f2f2f2;}
    #table tr:nth-child(odd){background-color: #e7e6e6;}

    #table tr:hover {background-color: #ddd;}

    #table td{
        text-align: center;
        color: var(--secondary);
    }
     #table td img{
         max-width: 80px;
     }
    #table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: #04AA6D;
        color: white;
    }
    .no-results{
        width: 100%;
        text-align: center;
        color: #fff;
    }


</style>